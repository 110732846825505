<template>
    <div class="couponCard">
        <div class="content">
            {{text}}
        </div>
    </div>
</template>

<script>
export default {
    props:{
        text:{
            type:String,
            default:'马上领取500原优惠券'
        }
    },
    data(){
        return {

        }
    },
    methods: {
        
    },
}
</script>

<style lang="less" scoped>
.couponCard{
    background-color: #F5EAEA;
    .content{
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        font-family: Helvetica;
        color: #C61F1F;
        font-weight: bold;
    }
}
</style>

