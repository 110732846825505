<template>
    <div class="selectClass">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.push('/')">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span class="title" @click="toSelectLearning" style="font-size:17px;font-weight:500;">{{getClassParams.learningName}}</span>
                <i @click="toSelectLearning" class="van-icon question_back_choose"></i>
            </template>
            <template #right>
                <van-icon name="search" size="25" color="#141414" @click="toSearch" />
            </template>
        </van-nav-bar>

        <!-- 选项卡 -->
        <van-tabs class="vanTabs" v-model="active" @change="tabChange" sticky offset-top="46px" animated swipeable color="#5D7DFF">
            <!-- 优惠券卡片 -->
            <couponCard v-if="isCouponCard" />

            <van-tab class="vanTab" title="全部">
                <van-pull-refresh v-model="loading" @refresh="onRefresh(0)">
                    <van-list v-model="loading2" offset="20" :immediate-check="false" :finished="finished" finished-text="没有更多了" @load="onLoadMore(0)">
                        <div class="videoCardBox" v-for="(item,i) in classData" :key="i">
                            <videoCard :content="item" />
                        </div>
                    </van-list>
                    <div style="height:100px;"></div>
                </van-pull-refresh>
            </van-tab>
            <van-tab class="vanTab" title="付费课程">
                <van-pull-refresh v-model="loading" @refresh="onRefresh(1)">
                    <van-list v-model="loading2" :immediate-check="false" :finished="finished1" finished-text="没有更多了" @load="onLoadMore(1)">
                        <div class="videoCardBox" v-for="(item,i) in classData1" :key="i">
                            <videoCard :content="item" />
                        </div>
                    </van-list>
                    <div style="height:100px;"></div>
                </van-pull-refresh>
            </van-tab>
            <van-tab class="vanTab" title="折扣课程">
                <van-pull-refresh v-model="loading" @refresh="onRefresh(2)">
                    <van-list v-model="loading2" :immediate-check="false" :finished="finished2" finished-text="没有更多了" @load="onLoadMore(2)">
                        <div class="videoCardBox" v-for="(item,i) in classData2" :key="i">
                            <videoCard :content="item" />
                        </div>
                    </van-list>
                    <div style="height:100px;"></div>
                </van-pull-refresh>
            </van-tab>
            <van-tab class="vanTab" title="免费课程">
                <van-pull-refresh v-model="loading" @refresh="onRefresh(3)">
                    <van-list v-model="loading2" :immediate-check="false" :finished="finished3" finished-text="没有更多了" @load="onLoadMore(3)">
                        <div class="videoCardBox" v-for="(item,i) in classData3" :key="i">
                            <videoCard :content="item" />
                        </div>
                    </van-list>
                    <div style="height:100px;"></div>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>

        <!-- 底部导航 -->
        <tabBar></tabBar>
    </div>
</template>

<script>
import couponCard from '@/pages/selectClass/components/couponCard' //优惠券卡片 组件
import videoCard from '@/pages/selectClass/components/videoCard' //课程卡片 组件
import tabBar from '@/components/tabBar' //底部导航 组件

import { getClass } from '@/api/selectClass.js' //获取课程 api
import { mapGetters } from "vuex"; 

export default {
    components: { tabBar, couponCard, videoCard },
    name:'xuanke',
    data() {
        return {
            active: 0,
            isCouponCard: false,
            loading: false,
            loading2: false,

            // 获取课程  参数
            getClassParams: {
                learningName:'', //学习方向名称
                firstTypeId: '', //学习方向id如：初级会计师137 中级会计师138
            },

            // 获取课程
            classData: [],  //0、全部
            finished: false,
            count: 0,
            allPage: 0,

            classData1: [], //1、付费
            count1: 0,
            finished1: false,
            fuFeiPage: 0,

            classData2: [], //2、折扣
            count2: 0,
            finished2: false,
            zheKouPage: 0,

            classData3: [], //3、免费
            count3: 0,
            finished3: false,
            mianFeiPage: 0,
        }
    },
    computed:{
        ...mapGetters(['getDirection']),//{firstId:'137',learningName:'初级会计师'}
    },
    created() {
        if( !localStorage.getItem('firstId') ){
            this.$router.push({ path: "/directionList/xuanke" });
        }

        this.getClassParams.firstTypeId = this.getDirection.firstId
        this.getClassParams.learningName = this.getDirection.learningName
        console.log('选课参数--------',this.getClassParams)
        
        this.getAllClass()
        this.getFuFeiClass()
        this.getZheKouClass()
        this.getMianFeiClass()
    },
    methods: {
        // 获取全部课程
        getAllClass() {
            let formData = new FormData()
            formData.append('firstTypeId', this.getClassParams.firstTypeId)
            formData.append('page', this.allPage)
            formData.append('type', 0)

            getClass(formData).then(res => {
                console.log('获取全部课程---', res)
                if (res.data.code == 0) {
                   this.classData.push(...res.data.data)
                   this.count = res.data.count
                }
                this.loading = false
                this.loading2 = false
            })
        },
        // 获取付费课程
        getFuFeiClass() {
            let formData = new FormData()
            formData.append('firstTypeId', this.getClassParams.firstTypeId)
            formData.append('page', this.fuFeiPage)
            formData.append('type', 1)

            getClass(formData).then(res => {
                console.log('获取付费课程---', res)
                if (res.data.code == 0) {
                   this.classData1.push(...res.data.data)
                   this.count1 = res.data.count
                }
                this.loading = false
                this.loading2 = false
            })
        },
        // 获取折扣课程
        getZheKouClass() {
            let formData = new FormData()
            formData.append('firstTypeId', this.getClassParams.firstTypeId)
            formData.append('page', this.zheKouPage)
            formData.append('type', 2)

            getClass(formData).then(res => {
                console.log('获取折扣课程---', res)
                if (res.data.code == 0) {
                   this.classData2.push(...res.data.data)
                   this.count1 = res.data.count
                }
                this.loading = false
                this.loading2 = false
            })
        },
        // 获取免费课程
        getMianFeiClass() {
            let formData = new FormData()
            formData.append('firstTypeId', this.getClassParams.firstTypeId)
            formData.append('page', this.mianFeiPage)
            formData.append('type', 3)

            getClass(formData).then(res => {
                console.log('获取免费课程---', res)
                if (res.data.code == 0) {
                   this.classData3.push(...res.data.data)
                   this.count3 = res.data.count
                }
                this.loading = false
                this.loading2 = false
            })
        },


        // 选择学习方向
        toSelectLearning() {
            this.$router.push({ path: "/directionList/xuanke" });
        },
        // 跳搜索页
        toSearch() {
            this.$router.push({ path: "/search" });
        },
        // tab栏切换
        tabChange(active) {
            // console.log('tab栏切换---',active)
            this.getClassParams.page = 0
            this.getClassParams.pageSize = 10
            this.loading2 = false
        },
        // 下拉刷新
        onRefresh(type) {
            console.log('下拉刷新type---', type)
            if (type == 0) { 
                this.classData = []
                this.count = 0
                this.allPage= 0
                this.finished = false
                this.getAllClass()
            }
            if (type == 1) { 
                this.classData1 = [];
                this.count1 = 0
                this.finished1 = false
                this.fuFeiPage = 0
                this.getFuFeiClass()
            }
            if (type == 2) { 
                this.classData2 = [];
                this.count2 = 0
                this.finished2 = false
                this.zheKouPage = 0
                this.getZheKouClass()
            }
            if (type == 3) { 
                this.classData3 = [];
                this.count3 = 0
                this.finished3 = false
                this.mianFeiPage = 0
                this.getMianFeiClass()
            }
            this.loading = false
        },
        // 上拉加载更多
        onLoadMore(type) {
            this.loading2 = true

            if(type == 0){ //全部
                this.allPage ++
                if(this.classData.length >= this.count) {
                    this.finished = true; this.loading2 = false
                    return
                }
                this.getAllClass()
            }
            if(type == 1){ //付费
                this.fuFeiPage ++
                if(this.classData1.length >= this.count1) {
                    this.finished1 = true; this.loading2 = false 
                    return
                }
                this.getFuFeiClass()
            }
            if(type == 2){ //折扣
                this.zheKouPage ++
                if(this.classData2.length >= this.count2) {
                    this.finished2 = true; this.loading2 = false;
                    return
                }
                this.getZheKouClass()
            }
            if(type == 3){ //免费
                this.mianFeiPage ++
                if(this.classData3.length >= this.count3) {
                    this.finished3 = true; this.loading2 = false;
                    return
                }
                this.getMianFeiClass()
            }
            console.log('上拉加载更多---')
        }

    },
}
</script>

<style lang="less" scoped>
.selectClass {
    height: 100vh;
    font-family: PingFangSC-Medium, PingFang SC;
    position: relative;
    // background-color: pink;

    .navBar{

        .question_back_choose {
            width: 17px;
            height: 15px;
            margin-left: 10px;
            background: url("../../assets/all_Icon/drawable-xxhdpi/question_back_choose.png")
                no-repeat 100% 100%/100%;
        }
    }

    .van-list {
        min-height: 70vh;
    }
}
</style>

