<template>
    <div class="videoCard" @click="goClassCenter(content)">
        <div class="img">
            <van-image fit="cover" width="155.7px" height="90px" :src="content.coverPath" />
            <div class="imgTag1" v-if="!content.price">免费</div>
            <!-- <div class="imgTag2">{{content.tagText}}</div> -->
        </div>
        <div class="text">
            <p class="text_p1 ellipsis">{{content.courseName}}</p>
            <p class="text_p2 mult_line_ellipsis">{{content.courseSecondName}}</p>
            <div class="text_div">

                <p v-if="content.discountPrice" class="text_p3">¥{{content.discountPrice}}</p>
                <p v-if="content.price && content.discountPrice==null || content.discountPrice== ''" class="text_p3">¥{{content.price}}</p>
                <p v-else-if="content.discountPrice" class="text_p4 lineThrough">¥{{content.price}}</p>
                <p v-else class="text_p3">免费</p>
                
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            default: ()=>{
                return{
                    url:'https://img01.yzcdn.cn/vant/cat.jpeg',
                    tagText:'直播',
                    title:'中级零基础【初级会计实务 初级会计实务】',
                    text:'全程直播授课，智能题库刷题，全程直播授课，智能题库刷题，全程直播授课，智能题库刷题，全程直播授课，智能题库刷题',
                    price: 0,
                }
            }
        }
    },
    data() {
        return { }
    },
    methods: {
        goClassCenter(content){
            // console.log('选课-videoCard组件---',content)
            this.$router.push('/ke/' + content.coursePackageId)
        }
    },
}
</script>

<style lang="less" scoped>
.videoCard {
    // background-color: pink;
    margin: 15px 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    display: flex;
    // height: 2.8rem;
    height: 90px;
    
    .img {
        margin-right: 5px;
        position: relative;

        .imgTag1 {
            width: 40px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            color: #FFFFFF;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            left: 0;
            top: 0;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .imgTag2 {
            width: 40px;
            height: 40px;
            text-align: right;
            font-size: 10px;
            line-height: 20px;
            color: #FFFFFF;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            right: 0;
            top: 0;
        }

        
    }
    .text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .text_p1{
            font-size: 14px;
            font-weight:bold;
            color: #333333;
            width:192px;
        }
        .text_p2{
            font-size: 0.3rem;
            color: #666666;
            font-weight: 400;
            width:192px;
        }
        .text_div{
            display: flex;
            .lineThrough{
                text-decoration: line-through;
            }
            .text_p3{
                color:red;
                font-size: 14px;
            }
            .text_p4{
                margin-left: 10px;
                font-size: 14px;
            }
        }
        
    }
    
}

</style>

