<template>
    <div>
        <router-view />

        <van-tabbar z-index="999" route v-model="active" @change="onChange">
            <van-tabbar-item replace to="/" icon="home-o">
                <template #icon="props">
                    <img :src="props.active ? icon1.active : icon1.inactive" />
                </template>
                <span>首页</span>
            </van-tabbar-item>

            <van-tabbar-item replace to="/xuanke" icon="column">
                <template #icon="props">
                    <img :src="props.active ? icon2.active : icon2.inactive" />
                </template>
                <span>选课</span>
            </van-tabbar-item>

            <van-tabbar-item replace to="/learning/:from?" icon="records">
                <template #icon="props">
                    <img :src="props.active ? icon3.active : icon3.inactive" />
                </template>
                <span>学习</span>
            </van-tabbar-item>

            <van-tabbar-item replace to="/tiku" icon="todo-list-o">
                <template #icon="props">
                    <img :src="props.active ? icon4.active : icon4.inactive" />
                </template>
                <span>题库</span>
            </van-tabbar-item>

            <van-tabbar-item replace to="/my" icon="contact">
                <template #icon="props">
                    <img :src="props.active ? icon5.active : icon5.inactive" />
                </template>
                <span>我的</span>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    name: 'Tabbar',
    data() {
        return {
            active: 0,
            icon1: { //首页
                active: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_1_1.png'),
                inactive: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_1_2.png'),
            },
            icon2: { //选课
                active: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_2_1.png'),
                inactive: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_2_2.png'),
            },
            icon3: { //学习
                active: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_3_1.png'),
                inactive: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_3_2.png'),
            },
            icon4: { //题库
                active: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_4_1.png'),
                inactive: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_4_2.png'),
            },
            icon5: { //我的
                active: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_5_1.png'),
                inactive: require('@/assets/all_Icon/drawable-xxhdpi/icon_main_5_2.png'),
            }
        };
    },
    methods: {
        onChange(index) {
            this.active = index
        },
    },
};
</script>
<style scoped>
</style>